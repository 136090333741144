const responseType = 'code'
const clientId = '1656461853'
const redirectUrl = 'https://management-rescue-station.duskyhub.com'
const redirectUri = encodeURIComponent(redirectUrl)
const state = '8sw6rllsr2'
const scope = encodeURI('profile openid')
const uri = `https://access.line.me/oauth2/v2.1/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`
const grantType = 'authorization_code'
const clientSecret = 'bab66663d8968ff5ad64fe9b4da8f28e'
const getAccessTokenHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' }

// eslint-disable-next-line import/prefer-default-export
export const lineConfig = {
  responseType,
  clientId,
  redirectUri,
  redirectUrl,
  state,
  scope,
  uri,
  grantType,
  clientSecret,
  getAccessTokenHeaders
}
