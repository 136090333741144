import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import AppModule from '@/store/modules/app'
import LocalModule from '@/store/modules/local'
import AuthModule from '@/store/modules/auth'
import NotificationModule from '@/store/modules/notification'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'rescue-location-mms',
  storage: window.localStorage,
  modules: ['app', 'auth']
})

const store = new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule,
    local: LocalModule,
    notification: NotificationModule
  },
  plugins: [
    vuexLocal.plugin
  ]
})

export default store
