<template>
  <v-snackbar
    :value="isSnackbar"
    :timeout="timeout"
    :left="left"
    :absolute="absolute"
    :color="type !== 'warning' ? '#4F4F4F' : '#EDA437'"
    width="150px"
    elevation="0"
    top
    @input="updateSnackbar($event)">
    <div class="d-flex snackbar--container">
      <v-icon
        :color="type === 'warning' ? 'white' : 'success'"
        left
        small>
        {{ icon }}
      </v-icon>
      <div>
        {{ message }}
      </div>
    </div>
    <!-- <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        dark
        small
        @click="closeSnackbar()">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </template> -->
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: undefined
    },
    absolute: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 5000
    },
    closeable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    isSnackbar () {
      return this.value
    },
    icon () {
      let icon = 'mdi-information'
      if (this.type === 'success') {
        icon = 'mdi-checkbox-marked-circle'
      } else if (this.type === 'warning') {
        icon = 'mdi-alert-circle'
      } else if (this.type === 'error') {
        icon = 'mdi-alert'
      }
      return icon
    }
  },
  methods: {
    updateSnackbar (e) {
      this.$emit('input', e)
    },
    closeSnackbar () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
  .snackbar--container {
    width: 100%;
    text-align: center;
  }
</style>
