import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
Vue.prototype.$dayjs = dayjs

Vue.filter('dateFullTime', (val) => dayjs(val).format('DD/MM/YYYY , HH:mm:ss A'))
