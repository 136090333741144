import Vue from 'vue'
import i18n from '@/plugins/vue-i18n'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

import '@/plugins/vue2-google-maps'
import '@/plugins/lodash'
import '@/plugins/day'
import '@/plugins/vue-clipboard2'
import '@/plugins/vue-papa-parse'
import '@/plugins/filter'
// import '@/plugins/vue-socket-io'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
