<template>
  <div>
    <v-app-bar
      clipped-left
      app
      dark>
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl"
        @click="drawer = true" />
      <div class="d-flex justify-center justify-sm-start w-100">
        <img src="@/assets/img/logo.png" />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      width="220"
      app
      clipped>
      <v-container class="h-100">
        <div class="d-flex flex-column justify-space-between h-100">
          <v-list
            nav
            dense>
            <v-list-item
              v-for="(item, i) in items"
              :key="`navbar-item-${i}`"
              :to="{ name: item.to}"
              exact>
              <div class="d-flex align-center">
                <v-icon class="mr-4">
                  {{ item.icon }}
                </v-icon>
                <div class="navbar-item">
                  {{ item.title }}
                </div>
              </div>
            </v-list-item>
          </v-list>
          <div class="d-flex flex-column pa-4">
            <div
              style="word-wrap: break-word;"
              class="mb-2">
              {{ user.meta.firstName }} {{ user.meta.lastName }}
            </div>
            <div
              style="cursor: pointer;"
              @click="logout()">
              <v-icon large>
                mdi-logout
              </v-icon>
              {{ $t('app.menu.logout') }}
            </div>
          </div>
        </div>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  data () {
    return {
      drawer: false,
      items: [
        {
          title: this.$t('app.menu.location'),
          to: 'Location',
          icon: 'mdi-map-marker-radius-outline'
        },
        {
          title: this.$t('app.menu.request'),
          to: 'Request',
          icon: 'mdi-alarm-light-outline'
        },
        {
          title: this.$t('app.menu.member'),
          to: 'Member',
          icon: 'mdi-account-multiple'
        },
        {
          title: this.$t('app.menu.permission'),
          to: 'Permission',
          icon: 'mdi-account-cog'
        },
        {
          title: this.$t('app.menu.log'),
          to: 'Log',
          icon: 'mdi-note-outline'
        },
        {
          title: this.$t('app.menu.map'),
          to: 'Map',
          icon: 'mdi-map-search-outline'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  mounted () {
    if (this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.md) {
      this.drawer = true
    }
  },
  methods: {
    ...mapActions({
      clearUser: 'auth/clearUser'
    }),
    logout () {
      this.clearUser()
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped>
  .h-100 {
    height: 100%;
  }
  .navbar-item {
    font-size: 14px;
    font-weight: 500;
  }
</style>
