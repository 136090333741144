import _ from 'lodash'

const state = {
  roles: [],
  lineData: {
    sub: ''
  },
  myLocation: {
    lat: '',
    lng: ''
  }
}

const mutations = {
  SET_ROLES (state, payload) {
    state.roles = payload
  },
  SET_LINEDATA (state, payload) {
    state.lineData = _.cloneDeep(payload)
  },
  CLEAR_LINEDATA (state) {
    state.lineData = _.cloneDeep({ sub: '' })
  },
  SET_MYLOCATION (state, payload) {
    state.myLocation = {
      ...payload
    }
  }
}

const actions = {
  setRoles ({ commit }, payload) {
    commit('SET_ROLES', payload)
  },
  setLineData ({ commit }, payload) {
    commit('SET_LINEDATA', payload)
  },
  clearLineData ({ commit }) {
    commit('CLEAR_LINEDATA')
  },
  setMyLocation ({ commit }, payload) {
    commit('SET_MYLOCATION', payload)
  }
}

const getters = {
  roles: (state) => state.roles,
  lineData: (state) => state.lineData,
  myLocation: (state) => state.myLocation
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
