import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from 'axios'
import qs from 'qs'
import { lineConfig } from '@/plugins/line'
import RoleProvider from '@/resources/axios/providers/role'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register.vue')
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import('@/views/location/Location.vue'),
    children: [
      {
        path: 'create',
        name: 'LocationCreate',
        component: () => import('@/views/location/pages/LocationCreate.vue')
      },
      {
        path: 'update/:locationId',
        name: 'LocationUpdate',
        component: () => import('@/views/location/pages/LocationUpdate.vue')
      },
      {
        path: 'import',
        name: 'LocationImport',
        component: () => import('@/views/location/pages/LocationImport.vue')
      }
    ]
  },
  {
    path: '/request',
    name: 'Request',
    component: () => import('@/views/request/Request.vue')
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('@/views/member/Member.vue'),
    children: [
      {
        path: 'update/:memberId',
        name: 'MemberUpdate',
        component: () => import('@/views/member/pages/MemberUpdate.vue')
      }
    ]
  },
  {
    path: '/permission',
    name: 'Permission',
    component: () => import('@/views/permission/Permission.vue'),
    children: [
      {
        path: 'update/:permissionId',
        name: 'PermissionUpdate',
        component: () => import('@/views/permission/pages/PermissionUpdate.vue')
      }
    ]
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import('@/views/log/Log.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('@/views/map/Map.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.query?.code) {
    const data = {
      grant_type: lineConfig.grantType,
      code: to.query?.code,
      redirect_uri: lineConfig.redirectUrl,
      client_id: lineConfig.clientId,
      client_secret: lineConfig.clientSecret
    }
    const accessTokenData = await axios.post(
      'https://api.line.me/oauth2/v2.1/token',
      qs.stringify(data),
      { headers: lineConfig.getAccessTokenHeaders }
    )
    const accessToken = accessTokenData.data
    const lineUserInfo = await axios.post(
      'https://api.line.me/oauth2/v2.1/verify',
      qs.stringify({
        id_token: accessToken.id_token,
        client_id: lineConfig.clientId
      }),
      { headers: lineConfig.getAccessTokenHeaders }
    )
    if (lineUserInfo?.data) {
      store.dispatch('local/setLineData', lineUserInfo?.data)
      next({ name: store.getters['app/currentPage'] })
    }
  }

  if (to.query?.invite) {
    store.dispatch('auth/setCode', to.query?.invite)
    next({ name: 'Register' })
  }

  if (store.getters['auth/user']?.accessToken && !store.getters['local/roles']?.length) {
    const res = await RoleProvider.getRoleList()
    if (res) {
      store.dispatch('local/setRoles', res)
    }
  }

  if ((to.name === 'Register' || to.name === 'Login') && store.getters['auth/user']?.accessToken) {
    next({ name: 'Location' })
  } else if (to.name === 'Home' && store.getters['auth/user']?.accessToken) {
    next({ name: 'Location' })
  } else if (to.name === 'Register') {
    next()
  } else if (to.name !== 'Login' && !store.getters['auth/user']?.accessToken) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
