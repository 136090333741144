<template>
  <v-app>
    <header>
      <navbar v-if="$route.name !== 'Login' && $route.name !== 'Register'" />
    </header>
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <v-overlay v-model="isLoading">
      <v-progress-circular
        indeterminate
        size="64" />
    </v-overlay>
    <snackbar
      :value="snackbar.value"
      :message="snackbar.message"
      :type="snackbar.type"
      :closeable="snackbar.closeable"
      @input="closeSnackbar()" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Snackbar from '@/components/alert/Snackbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Snackbar
  },
  data () {
    return {
      drawer: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'notification/snackbar'
    })
  },
  mounted () {
    this.getMyLocation()
  },
  methods: {
    ...mapActions({
      setMyLocation: 'local/setMyLocation'
    }),
    getMyLocation () {
      this.isLoading = true
      if (!('geolocation' in navigator)) {
        console.log('Geolocation is not available.')
        return
      }
      navigator.geolocation.getCurrentPosition((pos) => {
        const lat = pos.coords.latitude
        const lng = pos.coords.longitude
        this.setMyLocation({
          lat,
          lng
        })
        this.isLoading = false
      }, (err) => {
        this.isLoading = false
        console.error(err.message)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/styles.scss';
  @import '@/assets/scss/vuetify.scss';
</style>
