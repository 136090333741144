const state = {
  currentPage: ''
}

const mutations = {
  SET_CURRENTPAGE (state, payload) {
    state.currentPage = payload
  }
}

const actions = {
  setCurrentPage ({ commit }, payload) {
    commit('SET_CURRENTPAGE', payload)
  }
}

const getters = {
  currentPage: (state) => state.currentPage
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
