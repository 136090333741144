import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class RoleProvider extends HttpRequest {
  getRoleList = () => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get('/roles')
  }
}

export default new RoleProvider()
