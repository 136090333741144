import _ from 'lodash'

const state = {
  code: '',
  user: {
    accessToken: '',
    meta: {}
  },
  info: {}
}

const mutations = {
  SET_CODE (state, payload) {
    state.code = payload
  },
  SET_USER (state, payload) {
    state.user = _.cloneDeep(payload)
  },
  CLEAR_USER (state) {
    state.user = _.cloneDeep({
      accessToken: '',
      meta: {}
    })
  },
  SET_INFO (state, payload) {
    state.info = _.cloneDeep(payload)
  },
  CLEAR_INFO (state) {
    state.info = _.cloneDeep({})
  }
}

const actions = {
  setCode ({ commit }, payload) {
    commit('SET_CODE', payload)
  },
  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },
  clearUser ({ commit }) {
    commit('CLEAR_USER')
  },
  setInfo ({ commit }, payload) {
    commit('SET_INFO', payload)
  },
  clearInfo ({ commit }) {
    commit('CLEAR_INFO')
  }
}

const getters = {
  code: (state) => state.code,
  user: (state) => state.user,
  info: (state) => state.info
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
