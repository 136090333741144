import Vue from 'vue'

Vue.filter('displayTypeLocation', (val) => {
  switch (val) {
    case 'rescue':
      return 'กู้ภัย'
    case 'medical':
      return 'รถพยาบาล'
    case 'fire':
      return 'ดับเพลิง'
    default:
      return ''
  }
})
